import { environment } from '../../../environments/environment';

function isMobileSafari(): boolean {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent) && !/CriOS/i.test(navigator.userAgent);
}

function openLink(link): void {
  setTimeout((): void => {
    if (environment.openFileInNewTab) {
      const target: string = isMobileSafari() ? '_self' : '_blank';
      window.open(link, target);
    }
  }, 300);
}

export { openLink };
